* {
  box-sizing: border-box;
  margin: 0px;
  font-family: "sysfont";
}

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

// @import './components/home';

@font-face {
  font-family: "sysfont";
  src: url("../fonts/sysfont.woff") format("woff");
}

.space {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255, 254, 254);
  color: white;
  padding: 0px 10px 0px 10px;
}

#odyssey-wrapper {
  background-color: rgb(255, 254, 254);
}

.fixed-top-nav {
  position: fixed;
  top: 0;
  z-index: 999;
}


#home-container {
  display: flex;
  justify-content: center;
  align-content: center;
  // background-color: #101111;
  background-color: rgb(0, 0, 0);
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  padding-top: 100px;
  padding-bottom: 100px;
}



#about-container {
  display: flex;
  justify-content: center;
  align-content: center;
  // background-color: #000000;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  padding-top: 100px;
}

// h2,
.lead {
  text-align: center;
}

// .display-3 {
//   color: rgb(0, 0, 0);
// }

.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  max-height: 1400px;
  border-radius: 10px;
}


.logo {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}


#about-container {
  padding: 50px 10% 15px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.about-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  padding: 35px;
}

.display-5 {

  overflow: hidden;
  font-size: 20px;
  // color: rgb(255, 255, 255);
}


#skills-container {
  padding: 50px 10% 15px;
  padding-bottom: 100px;

}

.skills-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  padding: 35px;
  padding-top: 80px;
}

.card-body {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.cardtext {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  font-size: 26px;
}


.card {
  border: 0px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 100%;
  max-height: 100%;

}


.skillslogo-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
}

#projects-container {
  padding: 50px 10% 15px;
  padding-bottom: 100px;
}

.projects-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  padding: 35px;

}


.demo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1400px;
  max-height: 1400px;
  border-radius: 10px;
}


.demo {
  max-width: 150%;
  max-height: 100%;
  border-radius: 10px;
}

.sitelink {
  text-decoration: none;
  color: black;
  width: 70px;
  padding: 5px;
  margin-left: 30px;
}

.demologo-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  padding-bottom: 10px;

}

#interests-container {
  padding: 50px 10% 15px;
  padding-bottom: 100px;
}

.interests-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  padding: 35px;

}

#contact-container {
  padding: 50px 10% 15px;
  padding: 0px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  padding: 35px;
  margin-left: 141px;

}


.contatti {
  margin-left: -64px;
}



#space-button {
  width: 124px;
  height: 69px;
}